// extracted by mini-css-extract-plugin
export var avatar = "Testimonial__avatar__Ko8mj";
export var column = "Testimonial__column__cb32g";
export var companyLogo = "Testimonial__companyLogo__PTSZr";
export var container = "Testimonial__container__ZUXAc";
export var containerGutter = "Testimonial__containerGutter__Ltd_H";
export var detail = "Testimonial__detail__uc1B7";
export var disabled = "Testimonial__disabled__iNfmn";
export var error = "Testimonial__error__KqwqW";
export var from = "Testimonial__from__cI2oW";
export var grouped = "Testimonial__grouped__Ojcl9";
export var icon = "Testimonial__icon__fOn12";
export var input = "Testimonial__input__P6B8G";
export var leftIcon = "Testimonial__leftIcon__eYpqv";
export var link = "Testimonial__link__U4uKd";
export var marginButtom0 = "Testimonial__marginButtom0__KTFWG";
export var marginHorizontal0 = "Testimonial__marginHorizontal0__T3pdX";
export var marginLeft = "Testimonial__marginLeft__urvk3";
export var marginRight = "Testimonial__marginRight__liQCr";
export var marginTop0 = "Testimonial__marginTop0__PmKfS";
export var name = "Testimonial__name__X_xlV";
export var paddingHorizontal0 = "Testimonial__paddingHorizontal0__XyCxA";
export var profile = "Testimonial__profile__zxADB";
export var row = "Testimonial__row__cU542";
export var sizeLg = "Testimonial__sizeLg__QpiLk";
export var sizeMd = "Testimonial__sizeMd__GCkFZ";
export var sizeSm = "Testimonial__sizeSm___Ap7Q";
export var sizeXl = "Testimonial__sizeXl__dyYBG";
export var sizeXs = "Testimonial__sizeXs__neNbG";
export var sizeXxl = "Testimonial__sizeXxl__PCtcb";
export var sizeXxs = "Testimonial__sizeXxs__trYf0";
export var sizeXxxl = "Testimonial__sizeXxxl___zL_w";
export var sizeXxxs = "Testimonial__sizeXxxs__gOxRD";
export var sizeXxxxl = "Testimonial__sizeXxxxl__Z9H4N";
export var sizeXxxxxl = "Testimonial__sizeXxxxxl__G9E5H";
export var spinner = "Testimonial__spinner__t_NPY";
export var testimonialContainer = "Testimonial__testimonialContainer__wXrzK";
export var text = "Testimonial__text__jfxpD";
export var withLeftSideAddon = "Testimonial__withLeftSideAddon__i3W6N";
export var withRightSideAddon = "Testimonial__withRightSideAddon__T_VH9";
export var wrapper = "Testimonial__wrapper__LD4AM";