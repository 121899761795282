// extracted by mini-css-extract-plugin
export var column = "ExclusiveServices__column__GVkfZ";
export var description = "ExclusiveServices__description__tvOSz";
export var icon = "ExclusiveServices__icon__q_i6a";
export var link = "ExclusiveServices__link__xYTlb";
export var row = "ExclusiveServices__row___rPED";
export var service = "ExclusiveServices__service__zMSJG";
export var serviceBlock = "ExclusiveServices__serviceBlock__kAVwL";
export var services = "ExclusiveServices__services__tTMyw";
export var title = "ExclusiveServices__title__r6J2d";
export var wrapper = "ExclusiveServices__wrapper__M_DLl";