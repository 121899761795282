// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../styleguide/components/Heading/H2.res.js";
import * as H3 from "../../../../../../styleguide/components/Heading/H3.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as EvocativeServicesOverviewScss from "./EvocativeServicesOverview.scss";

var css = EvocativeServicesOverviewScss;

function EvocativeServicesOverview(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              children: "Enterprise-Grade Bare Metal Servers"
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("p", {
                                    children: "In a world where digital capabilities drive businesses, having a robust and reliable infrastructure is paramount. Evocative Metal delivers high-performance, dedicated compute power to support your data-intensive workloads."
                                  }),
                              className: css.serviceDetails
                            })
                      ],
                      className: css.service
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "Built for the Enterprise"
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("p", {
                                    children: "Evocative Metal servers are designed to handle your most demanding workloads. With robust CPU, memory, storage, and network configurations, Intel Gold and Silver processors, access to our global network backbone, your servers will stay ready for what’s next."
                                  }),
                              className: css.serviceDetails
                            })
                      ],
                      className: css.service
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "The Shift to Bare Metal"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: "The global bare metal market is seeing a resurgence. Some reasons for the growing interest in bare metal servers is the inherent limitations of conventional public clouds in handling high-performance tasks and providing sufficient dedicated resources for storage and other services, paired with unpredictable costs and egress fees."
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: "This reality is forcing many organizations to reconsider how they manage their data and high-performance workloads."
                                    })
                              ],
                              className: css.serviceDetails
                            })
                      ],
                      className: css.service
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "Benefits of Bare Metal Servers"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: "Bare metal servers offer an ideal response to the challenges that organizations are facing with the public cloud, with a solution designed for resource-intensive applications that provides the following benefits:"
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "High Performance:"
                                            }),
                                        JsxRuntime.jsx("p", {
                                              children: "Experience increased performance and lower latency with greater processing power."
                                            })
                                      ],
                                      className: css.benefits
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "Complete Control:"
                                            }),
                                        JsxRuntime.jsx("p", {
                                              children: "Gain direct access to compute resources and hardware-level performance for ultimate control and security."
                                            })
                                      ],
                                      className: css.benefits
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "Increased Security:"
                                            }),
                                        JsxRuntime.jsx("p", {
                                              children: "Isolate sensitive workloads with 100% single-tenancy and access hardware-level security protocols."
                                            })
                                      ],
                                      className: css.benefits
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "Agile Customization:"
                                            }),
                                        JsxRuntime.jsx("p", {
                                              children: "Flexible deployment options and the ability to bring your own software and design your own environment."
                                            })
                                      ],
                                      className: css.benefits
                                    })
                              ],
                              className: css.serviceDetails
                            })
                      ],
                      className: css.service
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "Unlock Your Potential"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: "With 25 years of expertise, Evocative is your long-term partner, offering unparalleled service and enterprise-grade technologies. With Evocative Metal, you can unlock your potential for innovation at scale by:"
                                    }),
                                JsxRuntime.jsxs("ul", {
                                      children: [
                                        JsxRuntime.jsx("li", {
                                              children: "Seamlessly integrating with your hybrid IT environment"
                                            }),
                                        JsxRuntime.jsx("li", {
                                              children: "Storing large amounts of fast-growing data in a cost-effective way"
                                            }),
                                        JsxRuntime.jsx("li", {
                                              children: "Leveraging cloud adjacency, colocation, and interconnection services"
                                            }),
                                        JsxRuntime.jsx("li", {
                                              children: "Retaining complete control over your tools, providers, and private data"
                                            }),
                                        JsxRuntime.jsx("li", {
                                              children: "Eliminating the drawbacks of vendor lock-in"
                                            })
                                      ]
                                    })
                              ],
                              className: css.serviceDetails
                            })
                      ],
                      className: css.service
                    })
              ],
              className: css.servicesOverview
            });
}

var make = EvocativeServicesOverview;

export {
  css ,
  make ,
}
/* css Not a pure module */
